if (window.matchMedia('(min-width: 1150px)').matches) {
  let didScroll;
  let lastScrollTop = 0;
  const delta = 5;
  const navbarHeight = jQuery('#header').outerHeight();

  jQuery(window).scroll((event) => {
    didScroll = true;
  });

  const hasScrolled = () => {
    const st = jQuery(this).scrollTop();

    if (Math.abs(lastScrollTop - st) <= delta) return;

    if (st > lastScrollTop && st > navbarHeight) {
      jQuery('#header').addClass('site-header--hide');
    } else {
      if (st + jQuery(window).height() < jQuery(document).height()) {
        jQuery('#header').removeClass('site-header--hide');
      }
    }

    lastScrollTop = st;
  };

  setInterval(() => {
    if (didScroll) {
      hasScrolled();
      didScroll = false;
    }
  }, 250);

  jQuery(window).scroll(() => {
    const scroll = jQuery(window).scrollTop();

    if (scroll >= 500) {
      jQuery('#header').addClass('site-header--scroll');
    } else {
      jQuery('#header').removeClass('site-header--scroll');
    }
  });
}

if (window.matchMedia('(max-width: 1150px)').matches) {
  jQuery('.menu-item-has-children').append(
    '<div class="menu-item-has-children__arrow"><i class="icofont-simple-down"></i></div>'
  );
  jQuery('.menu-item-has-children').on('click', (event) => {
    if (jQuery(event.target).closest('a').length) {
      return;
    }

    if (jQuery(this).find('> .sub-menu').hasClass('sub-menu--mobileactive')) {
      jQuery(this).find('> .sub-menu').removeClass('sub-menu--mobileactive');
      jQuery(this).removeClass('menu-item-has-children__arrow--rotate');
    } else {
      jQuery(this).find('> .sub-menu').addClass('sub-menu--mobileactive');
      jQuery(this).addClass('menu-item-has-children__arrow--rotate');
    }
  });

  jQuery('#open').on('click', () => {
    if (
      jQuery('.site-header__container__right').hasClass(
        'site-header__container__right--active'
      )
    ) {
      jQuery('.site-header__container__right').removeClass(
        'site-header__container__right--active'
      );
    } else {
      jQuery('.site-header__container__right').addClass(
        'site-header__container__right--active'
      );
    }
  });

  jQuery('#exit').on('click', () => {
    if (
      jQuery('.site-header__container__right').hasClass(
        'site-header__container__right--active'
      )
    ) {
      jQuery('.site-header__container__right').removeClass(
        'site-header__container__right--active'
      );
    } else {
      jQuery('.site-header__container__right').addClass(
        'site-header__container__right--active'
      );
    }
  });
}

const topbar = new Swiper('#topbar', {
  slidesPerView: 1,
  speed: 500,
  autoplay: {
    delay: 5000,
  },
});

const menuItemhHasChildren = document.querySelectorAll(
  '.menu-item-has-children'
);

menuItemhHasChildren.forEach((item) => {
  item.addEventListener('click', () => {
    item.classList.toggle('active');
    menuItemhHasChildren.forEach((itembis) => {
      if (itembis !== item) {
        itembis.classList.remove('active');
      }
    });
  });
});
